import axios from 'axios';
import BigNumber from 'bignumber.js';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';

const MAX_UINT32 = 2 ** 32;

function isNumber(val) {
  return typeof val === 'number';
}

function equalJSON(val1, val2) {
  return JSON.stringify(val1) === JSON.stringify(val2);
}

function formatDate(value, showYear) {
  if (!value || value === '') {
    return '';
  }
  const isCurrentYear = moment().year() === moment(value).year();
  const yearFormat = isCurrentYear && !showYear ? '' : ' YYYY';

  return moment(value).format(`DD MMM${yearFormat}`);
}

function formatTime(value) {
  return moment(value).format('HH:mm');
}

function formatDateTimeZ(value, showYear) {
  const isCurrentYear = moment().year() === moment(value).year();
  const yearFormat = isCurrentYear && !showYear ? '' : ' YYYY';
  return moment(value).format(`DD MMM${yearFormat}, HH:mm`);
}

function formatDateRange(startDate, endDate) {
  if (!startDate || !endDate) {
    return 'Invalid Date Range';
  }
  const start = moment(startDate);
  const end = moment(endDate);
  const isCurrentYear = moment().year() === start.year();
  const yearFormat = isCurrentYear ? '' : ' YYYY';

  if (start.year() !== end.year()) {
    return `${start.format('DD MMM YYYY')} - ${end.format('DD MMM YYYY')}`;
  }
  if (start.month() !== end.month()) {
    return `${start.format('DD MMM')} - ${end.format(`DD MMM${yearFormat}`)}`;
  }

  if (start.date() !== end.date()) {
    return `${start.format('DD')} - ${end.format(`DD MMM${yearFormat}`)}`;
  }

  return `${start.format(`D MMM ${yearFormat}, H:mm`)} - ${end.format('H:mm')}`;
}

function toDuration(endTime, startTime, units = 'minutes') {
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  if (end.isSameOrBefore(start)) {
    end.add(1, 'day');
  }

  return end.diff(start, units);
}

function formatDuration(value, units = 'minutes', showMinutes = true) {
  const dayStart = moment().startOf('day');
  const timeStart = moment(dayStart).add(Math.abs(value), units);
  return `${value < 0 ? '-' : ''}${timeStart.diff(dayStart, 'hours')}hrs${
    showMinutes ? timeStart.format(' m[mins]') : ''
  }`;
}

function setTime(baseDate, newTime) {
  const returnDate = baseDate.clone();
  const [hms, ms] = newTime.split('.');
  const [h, m, s] = hms.split(':');
  return returnDate
    .hours(h || 0)
    .minutes(m || 0)
    .seconds(s || 0)
    .milliseconds(ms || 0);
}

function setDate(baseDate, newDate) {
  if (!baseDate) {
    return newDate;
  }
  return setTime(newDate, baseDate.format('HH:mm:ss'));
}

function getStatusClass(value, maxValue = 100, prefix) {
  const ratio = Number(value) / maxValue;

  if (ratio > 0.7) {
    return `${prefix}success`;
  }
  if (ratio > 0.4) {
    return `${prefix}warning`;
  }

  return `${prefix}danger`;
}

// this function is called recursive and it will reassign the param
function emptyToNull(param) {
  const obj = param;
  Object.keys(obj).forEach((k) => {
    if (
      (obj[k] && typeof obj[k] === 'object' && emptyToNull(obj[k])) ||
      (!obj[k] && obj[k] !== undefined)
    ) {
      if (obj[k] === '') {
        obj[k] = null;
      }
    }
  });
  return obj;
}

// this function is called recursive and it will reassign the param
function nullToEmpty(param) {
  const obj = param;
  Object.keys(obj).forEach((k) => {
    if (
      (obj[k] && typeof obj[k] === 'object' && nullToEmpty(obj[k])) ||
      (!obj[k] && obj[k] !== undefined)
    ) {
      if (obj[k] === null) {
        obj[k] = '';
      }
    }
  });
  return obj;
}

function generateRandomNumbers(length = 1) {
  const array = new Uint32Array(length);
  window.crypto.getRandomValues(array);
  return array;
}

function random(multiplier = 1) {
  return Math.round((generateRandomNumbers()[0] * multiplier) / MAX_UINT32);
}

function sleep(ms) {
  return new Promise((resolve) => window.setTimeout(resolve, ms));
}

function getOverlap(slot1, slot2) {
  const maxStartTime = slot1[0] > slot2[0] ? slot1[0] : slot2[0];

  const minEndTime = slot1[1] < slot2[1] ? slot1[1] : slot2[1];

  if (maxStartTime > minEndTime) {
    return false;
  }

  return [maxStartTime, minEndTime];
}

const objSortComparator = (obj1, obj2, paramArray) => {
  const firstParam = paramArray[0];

  if (!firstParam) {
    return 0;
  }

  const isFunction = typeof firstParam === 'function';
  const isNegative = firstParam[0] === '-';
  const checkParam = isNegative ? firstParam.slice(1) : firstParam;

  const val1 = isFunction ? firstParam(obj1) : obj1[checkParam];
  const val2 = isFunction ? firstParam(obj2) : obj2[checkParam];

  if (val1 > val2) {
    return isNegative ? -1 : 1;
  }

  if (val2 > val1) {
    return isNegative ? 1 : -1;
  }

  return objSortComparator(obj1, obj2, paramArray.slice(1));
};

function sortArrayBy(array, ...params) {
  const arrayCopy = Array.from(array);
  if (params.length === 0) {
    return arrayCopy.sort();
  }
  return arrayCopy.sort((obj1, obj2) => objSortComparator(obj1, obj2, params));
}

function cleanObject(obj) {
  const returnObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      returnObj[key] = obj[key];
    }
  });
  return returnObj;
}

function toPaginatedTransform(method) {
  return axios.defaults.transformResponse.concat((data) => ({
    count: data.count,
    results: data.results.map(method),
  }));
}

function getApiErrorMessage(data) {
  if (Array.isArray(data) && typeof data[0] === 'string') {
    return data.join('<br/>');
  }

  if (data.message) {
    return data.message;
  }

  if (typeof data.non_field_errors === 'string') {
    return data.non_field_errors;
  }

  if (Array.isArray(data.non_field_errors)) {
    if (data.non_field_errors.length === 1) {
      return data.non_field_errors[0];
    }
    return data.non_field_errors
      .map((err, index) => `${index + 1}. ${err}`)
      .join('<br/>');
  }

  return Object.values(data)
    .map((fieldErrors) => `${getApiErrorMessage(fieldErrors)}`)
    .join('<br/>');
}

function getErrorMessage(error) {
  if (!error.response) {
    return error.message;
  }
  try {
    const { response } = error;
    if (!response.data) {
      return `${error.response.status}: ${error.response.statusText}`;
    }

    const { data } = response;

    if (typeof data !== 'object') {
      return error.message;
    }

    return getApiErrorMessage(data);
  } catch (err) {
    return error.message;
  }
}

const getHalf = (date) =>
  moment(date).hours() >= 12 ? 'Second Half' : 'First Half';

function formatHalfRange(d1, d2) {
  if (!d1 || !d2) {
    return 'Invalid Date Range';
  }
  if (moment(d2).diff(d1, 'hours') < 12) {
    return `${formatDate(d1)}, ${getHalf(d1)}`;
  }
  if (d2.isSame(d1, 'date')) {
    return `${formatDate(d1)}, ${getHalf(d1)} - ${getHalf(d2)}`;
  }
  return `${formatDate(d1)} ${getHalf(d1)} - ${formatDate(d2)} ${getHalf(d2)}`;
}

async function urlToFile(fileUrl, filename, mimeType) {
  const fileResponse = await fetch(fileUrl);
  const fileBlob = await fileResponse.arrayBuffer();
  return new File([fileBlob], filename, { type: mimeType });
}

function addToTime(baseTime, shift, unit = 'ms') {
  return moment(baseTime, 'HH:mm:ss').add(shift, unit).format('HH:mm:ss');
}

function round(val, roundTo = 1) {
  return BigNumber(val).div(roundTo).dp(0).times(roundTo).toNumber();
}

function toTimeString(date, refTime) {
  const isPast = date < refTime;
  const dayDiff = Math.abs(date.diff(refTime, 'days'));
  const hourDiff = Math.abs(date.diff(refTime, 'hours')) % 24;
  const minuteDiff = Math.abs(date.diff(refTime, 'minutes')) % 60;
  const secondDiff = Math.abs(date.diff(refTime, 'seconds')) % 60;

  const suffix = isPast ? ' ago' : '';

  return `${
    dayDiff
      ? dayDiff <= 1 && hourDiff
        ? `${dayDiff}d  ${hourDiff}h`
        : `${dayDiff}d`
      : hourDiff
      ? hourDiff <= 2 && minuteDiff
        ? `${hourDiff}h  ${minuteDiff}m`
        : `${hourDiff}h`
      : minuteDiff
      ? minuteDiff < 4 && secondDiff
        ? `${minuteDiff}m  ${secondDiff}s`
        : `${minuteDiff}m`
      : `${secondDiff}s`
  }${suffix}`;
}

export {
  addToTime,
  cleanObject,
  cloneDeep as cloneJSON,
  emptyToNull,
  equalJSON,
  formatDate,
  formatDateRange,
  formatDateTimeZ,
  formatDuration,
  formatHalfRange,
  formatTime,
  generateRandomNumbers,
  getErrorMessage,
  getHalf,
  getOverlap,
  getStatusClass,
  isNumber,
  nullToEmpty,
  random,
  round,
  setDate,
  setTime,
  sleep,
  sortArrayBy,
  toDuration,
  toPaginatedTransform,
  toTimeString,
  urlToFile,
};
