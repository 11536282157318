import moment from 'moment';
import 'moment-timezone/moment-timezone-utils';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

function setSystemTz(name = 'Etc/UTC') {
  moment.tz.setDefault(name);
}

// * Same date and time but in system timezone
// * For output from native date components
function localToSystem(date) {
  if (!date) {
    return date;
  }

  const localOffset = moment(date).local().utcOffset();
  const systemOffset = moment().utcOffset();

  const newDate = moment(date)
    .add(localOffset - systemOffset, 'minutes')
    .utcOffset(systemOffset);

  return newDate;
}

function systemToLocal(date) {
  if (!date) {
    return date;
  }

  const systemOffset = moment().utcOffset();
  const localOffset = moment(date).local().utcOffset();

  return moment(date)
    .add(systemOffset - localOffset, 'minutes')
    .utcOffset(localOffset);
}

export {
  setSystemTz,
  localToSystem,
  systemToLocal,
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
};
