import COOKIE_DOMAIN from 'hcms-constants/COOKIE_DOMAIN';
import Cookie from 'js-cookie';
import PATHS from 'hcms-constants/APP_PATHS';
import axios from 'axios';
import { roleUtils } from 'hcms-const-utils';

const ROLES = roleUtils.DICT;
const STAFF_ROLES = [ROLES.CTR, ROLES.MGT, ROLES.SUP, ROLES.HR, ROLES.FIN];
const cookieSettings = { domain: COOKIE_DOMAIN };
const debug = process.env.VUE_APP_DEBUG === 'true';

function hasAccess(path, role) {
  if (
    path.includes(PATHS.AUTH) ||
    path.includes(PATHS.LOG_REQUEST) ||
    path.includes(PATHS.NEW_FORM_SUBMISSION)
  ) {
    return true;
  }
  if (path.includes(PATHS.CW)) {
    return [ROLES.CW].includes(role);
  }
  if (path.includes(PATHS.SU)) {
    return [ROLES.SU].includes(role);
  }
  if (path.includes(PATHS.ADMIN) || path.includes(PATHS.FORMS)) {
    return STAFF_ROLES.includes(role);
  }
  if (path.includes(PATHS.DB_MANAGEMENT)) {
    return [ROLES.ADMIN].includes(role);
  }
  return false;
}

function storeUserCookies(user) {
  Cookie.set('isSuperUser', user.isSuperUser, cookieSettings);
  Cookie.set('isFormManager', user.isFormManager, cookieSettings);
  Cookie.set('lastLogin', user.lastLogin, cookieSettings);
  Cookie.set('role', user.role, cookieSettings);
  Cookie.set('token', user.token, cookieSettings);
  Cookie.set('userName', user.userName, cookieSettings);
}

function getUserCookies() {
  const token = Cookie.get('token');
  const isAuthenticated = Boolean(Cookie.get('token'));

  if (!isAuthenticated) {
    return { isAuthenticated };
  }

  const role = Number(Cookie.get('role'));
  const roleConst = roleUtils.getByValue(role);
  const userName = Cookie.get('userName');
  const isSuperUser = Cookie.get('isSuperUser') === 'true';
  const isFormManager = Cookie.get('isFormManager') === 'true';
  const lastLogin = Cookie.get('lastLogin');

  return {
    token,
    isAuthenticated,
    role,
    roleConst,
    userName,
    isSuperUser,
    isFormManager,
    lastLogin,
  };
}

function clearUserCookies() {
  Cookie.remove('isSuperUser', cookieSettings);
  Cookie.remove('isFormManager', cookieSettings);
  Cookie.remove('lastLogin', cookieSettings);
  Cookie.remove('role', cookieSettings);
  Cookie.remove('token', cookieSettings);
  Cookie.remove('userName', cookieSettings);
}

function routeToDashboard() {
  const { role } = getUserCookies();

  const redirectUrl = new URL(window.location.href).searchParams.get(
    'redirect_url',
  );
  const isMobile = window.innerWidth < 768;
  if (redirectUrl && hasAccess(redirectUrl, role)) {
    window.location.replace(redirectUrl);
    return;
  }

  if (STAFF_ROLES.includes(role)) {
    if (role === ROLES.SUP && isMobile) {
      window.location.assign(`${PATHS.NEW_FORM_SUBMISSION}/requests`);
    } else {
      window.location.assign(PATHS.ADMIN);
    }
  } else if (role === ROLES.ADMIN) {
    window.location.assign(PATHS.DB_MANAGEMENT);
  } else if (role === ROLES.CW) {
    window.location.assign(PATHS.CW);
  } else if (role === ROLES.SU) {
    window.location.assign(PATHS.SU);
  } else {
    throw new Error('Unrecognized user type. Please contact admin.');
  }
}

function redirectToAuth(redirectUrl) {
  if (redirectUrl) {
    window.location.replace(`${PATHS.AUTH}?redirect_url=${redirectUrl}`);
  } else {
    window.location.assign(PATHS.AUTH);
  }
}

async function logout(isAutoLogout) {
  const url = 'api/auth/logoutall';
  axios.post(url);
  clearUserCookies();

  if (isAutoLogout || debug) {
    redirectToAuth(window.location.href);
  } else {
    redirectToAuth();
  }
}

const currentUser = getUserCookies();

export default {
  routeToDashboard,
  storeUserCookies,
  getUserCookies,
  clearUserCookies,
  redirectToAuth,
  logout,
  currentUser,
};

export {
  routeToDashboard,
  storeUserCookies,
  clearUserCookies,
  redirectToAuth,
  logout,
  currentUser,
};
