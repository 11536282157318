<template>
  <div id="app">
    <div class="app-container">
      <router-view :key="`${$route.name} ${JSON.stringify($route.params)}`"></router-view>
      <form-nav v-if="!$route.meta.noNav" />
    </div>
  </div>
</template>

<script>
import FormNav from 'components/FormNav.vue';

export default {
  name: 'app',
  components: {
    FormNav,
  },
};
</script>
<style lang="scss">
.app-container {
  max-width: 480px;
  margin: auto;
}
</style>
