import { get as getConfig } from 'hcms-transforms/config';
import { setSystemTz } from 'tz-utils';

async function setDefaultTimezone() {
  // TODO: Remove try-catch
  try {
    const configs = await getConfig(['CLIENT_TIMEZONE']);

    const { CLIENT_TIMEZONE } = configs;

    if (!CLIENT_TIMEZONE) {
      throw new Error('Config not received');
    }
    const timezone = CLIENT_TIMEZONE.getValue();
    setSystemTz(timezone);
  } catch (error) {
    setSystemTz();
  }
}

export default setDefaultTimezone;
