const AUTH = process.env.VUE_APP_AUTH_PATH;
const TOOLS = process.env.VUE_APP_TOOLS_PATH;
const ADMIN = process.env.VUE_APP_ADMIN_PATH;
const CW = process.env.VUE_APP_CW_PATH;
const SU = process.env.VUE_APP_SU_PATH;
const SERVER_ADDRESS = process.env.VUE_APP_SERVER_PATH;
const LOG_REQUEST = `${process.env.VUE_APP_TOOLS_PATH}/log-request`;
const NEW_FORM_SUBMISSION = `${process.env.VUE_APP_TOOLS_PATH}/form`;
const DB_MANAGEMENT = `${process.env.VUE_APP_TOOLS_PATH}/db-management`;

export {
  AUTH,
  TOOLS,
  ADMIN,
  CW,
  SU,
  SERVER_ADDRESS,
  LOG_REQUEST,
  NEW_FORM_SUBMISSION,
  DB_MANAGEMENT,
};

export default {
  AUTH,
  TOOLS,
  ADMIN,
  CW,
  SU,
  SERVER_ADDRESS,
  LOG_REQUEST,
  NEW_FORM_SUBMISSION,
  DB_MANAGEMENT,
};
