export default [
  {
    value: 1,
    label: 'Management',
    shortName: 'MGT',
    name: 'MGT',
    isStaff: true,
  },
  {
    value: 2,
    label: 'Service User',
    shortName: 'SU',
    name: 'SU',
  },
  {
    value: 3,
    label: 'Care Worker',
    shortName: 'CW',
    name: 'CW',
  },
  {
    value: 4,
    label: 'Supervisor',
    shortName: 'SUP',
    name: 'SUP',
    isStaff: true,
  },
  {
    value: 5,
    label: 'Human Resources',
    shortName: 'HR',
    name: 'HR',
    isStaff: true,
  },
  {
    value: 6,
    label: 'Coordinator',
    shortName: 'CTR',
    name: 'CTR',
    isStaff: true,
  },
  {
    value: 7,
    label: 'Admin',
    shortName: 'ADMIN',
    name: 'ADMIN',
    isInternal: true,
  },
  {
    value: 8,
    label: 'System',
    shortName: 'SYS',
    name: 'SYS',
    isInternal: true,
  },
  {
    value: 9,
    label: 'Finance',
    shortName: 'FIN',
    name: 'FIN',
    isStaff: true,
  },
  {
    value: 11,
    label: 'Dev',
    name: 'Dev',
    isInternal: true,
  },
  {
    value: 12,
    label: 'Next Of Kin',
    name: 'NOK',
    isInternal: true,
  },
];
