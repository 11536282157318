import { Message } from 'element-ui';
import { getErrorMessage } from 'global-utils';

const defaultTimeout = 3000;
const errorTimeout = 7000;

const getTimeout = (type) => (type === 'error' ? errorTimeout : defaultTimeout);

const showNotification = (message, type, timeout) => {
  Message({
    message,
    duration: typeof timeout === 'number' ? timeout : getTimeout(type),
    showClose: true,
    type,
    dangerouslyUseHTMLString: true,
  });
};

function notify(notification, type, timeout) {
  if (!notification) {
    throw new Error('Please provide notification message');
  }

  if (typeof notification === 'string') {
    showNotification(notification, type, timeout);
    return;
  }

  if (notification instanceof Error) {
    // eslint-disable-next-line no-console
    const message = getErrorMessage(notification);
    showNotification(message, type, timeout);
    window.setTimeout(() => {
      throw notification;
    });
    return;
  }

  throw new Error('Please provide notification message');
}

const Plugin = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$notify = notify;
  },
};

export { Plugin, notify };
export default Plugin;
