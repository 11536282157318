import axios from 'axios';
import { constantCase } from 'change-case';

const DEFAULT = 'DEFAULT';

function isTruthy(value) {
  return Boolean(Number(value));
}

class Config {
  static isTruthy = isTruthy;

  constructor(rawConfig) {
    this.name = rawConfig[0].code_type;
    this.config = rawConfig.map((subtype) => ({
      name: subtype.code_sub_type || DEFAULT,
      value: subtype.code_val,
    }));
    this.config.forEach((subtype) => {
      this[constantCase(subtype.name)] = subtype.value;
    });
  }

  getValue(name = DEFAULT) {
    const v = this.config.find((subtype) => subtype.name === name);
    return !v ? null : v.value;
  }
}

function transformResponse(configs) {
  const entries = Object.entries(configs).map(([configName, config]) => [
    configName,
    new Config(config),
  ]);
  return Object.fromEntries(entries);
}

async function get(codeTypes, isVm) {
  if (!Array.isArray(codeTypes) || codeTypes.length === 0) {
    return [];
  }
  const url = isVm ? 'api/vmconfig' : 'api/config';
  const response = await axios.get(url, {
    params: {
      code_type: codeTypes.toString(),
    },
  });
  return transformResponse(response.data);
}

export { Config, isTruthy, get };
