import Vue from 'vue';
import Element, {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  Option,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableColumn,
  Tag,
  Tooltip,
  Upload,
  Loading,
} from 'element-ui';

Vue.use(Element, { size: 'small' });

Vue.use(Alert);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Option);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tag);
Vue.use(Tooltip);
Vue.use(Upload);

Vue.use(Loading.directive);
