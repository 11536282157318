import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VeeElement from 'vee-element';

VeeValidate.Validator.localize('en', {
  messages: {
    required() {
      return 'Required';
    },
    regex() {
      return 'Invalid Format';
    },
    is_not(fieldName, value) {
      return `Must not be ${value}`;
    },
  },
});
const validator = new VeeValidate.Validator({}, {});
Vue.use(VeeElement, validator);
