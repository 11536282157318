import { ADMIN } from 'hcms-constants/APP_PATHS';
import Router from 'vue-router';
import Vue from 'vue';
import { logout } from 'auth-utils';

Vue.use(Router);

const LogRequest = () => import(/* webpackChunkName:"LogRequest" */ 'views/LogRequest.vue');
const DbManagement = () => import(/* webpackChunkName:"DbManagement" */ 'views/DbManagement.vue');
const NotFound = () => import(/* webpackChunkName:"NotFound" */ 'views/NotFound.vue');
const FormEntry = () => import(/* webpackChunkName:"FormEntry" */ 'views/FormBuilder/FormEntry.vue');
const MySubmissions = () => import(/* webpackChunkName:"MySubmissions" */ 'views/FormBuilder/MySubmissions.vue');
const FormRequests = () => import(/* webpackChunkName:"FormRequests" */ 'views/FormBuilder/FormRequests.vue');
const ObaForms = () => import(/* webpackChunkName:"ObaForms" */ 'views/FormBuilder/ObaForms.vue');

const desktopFormRequestPath = `${ADMIN}/form/requests`;

const routes = [
  {
    path: '/log-request',
    name: 'Log Request',
    component: LogRequest,
  },
  {
    path: '/db-management',
    name: 'DB Management',
    component: DbManagement,
    meta: {
      noNav: true,
    },
  },
  {
    path: '/form/submissions',
    name: 'My Form Submissions',
    component: MySubmissions,
  },
  {
    path: '/form/requests',
    name: 'Form Submission Requests',
    component: FormRequests,
  },
  {
    path: '/form/request/:formRequestId/submit/:formId',
    name: 'New Form Submission',
    component: FormEntry,
    props: (route) => ({
      formId: Number(route.params.formId),
      formRequestId: Number(route.params.formRequestId),
    }),
  },
  {
    path: '/form/oba/:userName',
    name: 'Outcome Based Assessment Forms',
    component: ObaForms,
    props: (route) => ({
      userName: route.params.userName,
    }),
  },
  {
    path: '/form/oba/:userName/submit/:formId',
    name: 'OBA Form Submission',
    component: FormEntry,
    props: (route) => ({
      formId: Number(route.params.formId),
      userName: route.params.userName,
    }),
  },
  {
    path: '/form/:formId/submission/:formEntryId',
    name: 'My Form Submission',
    component: FormEntry,
    props: (route) => ({
      formId: Number(route.params.formId),
      formEntryId: Number(route.params.formEntryId),
    }),
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter() {
      logout();
    },
  },
  {
    path: '/desktop-form-requests',
    name: 'Desktop Form Requests',
    beforeEnter() {
      window.location.href = desktopFormRequestPath;
    },
  },
  {
    path: '*',
    redirect: {
      name: 'Not Found',
    },
  },
  {
    path: '/not-found',
    name: 'Not Found',
    component: NotFound,
  },
];

const router = new Router({
  routes,
  mode: 'history',
});

export default router;
