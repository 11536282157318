<template>
  <el-dropdown
    class="floating-nav floating-nav--bottom floating-nav--right"
    trigger="click"
    @command="$route.name !== $event ? $router.push({ name: $event }) : ''">
    <el-button class="form-nav__button" circle size="medium" icon="el-icon-menu"> </el-button>
    <el-dropdown-menu class="form-nav__dropdown" slot="dropdown">
      <el-dropdown-item command="My Form Submissions">My Form Submissions</el-dropdown-item>
      <el-dropdown-item command="Form Submission Requests">Form Submission Requests</el-dropdown-item>
      <el-dropdown-item v-if="isSup" command="Desktop Form Requests">Desktop Form Requests</el-dropdown-item>
      <el-dropdown-item command="Log Request">Log Request</el-dropdown-item>
      <el-dropdown-item command="Logout">Logout</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { currentUser } from 'auth-utils';
import { roleUtils } from 'hcms-const-utils';

export default {
  name: 'FormNav',
  computed: {
    isSup() {
      return currentUser.role === roleUtils.DICT.SUP;
    },
  },
};
</script>
<style lang="scss">
.form-nav {
  &__button {
    border-radius: 6px;
  }

  &__dropdown {
    .popper__arrow {
      opacity: 0;
    }
  }
}
</style>
