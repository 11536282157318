import './setup/checkAuthentication';
import './setup/axios';
import './setup/locale';
import './setup/element';
import './setup/validator';

import 'assets/scss/main.scss';

import Vue from 'vue';
import VueConstants from 'vue-constants';

import Notify from 'utils/notify';
import DatePicker from 'hcms-components/DatePicker.vue';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import router from './router';

import App from './App.vue';
import setDefaultTimezone from './setup/timezone';

import 'element-ui/packages/theme-chalk/src/message.scss';

Vue.use(VueConstants);
Vue.use(Notify);
Vue.component('DatePicker', DatePicker);

// * Configure Element
locale.use(lang);

// * Configure Axios

Vue.config.productionTip = false;

setDefaultTimezone().then(() => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    render: (h) => h(App),
    router,
  });
});
