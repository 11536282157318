import { constantCase } from 'change-case';
import { sortArrayBy } from 'global-utils';
import ANTI_BOOLEAN from 'hcms-constants/ANTI_BOOLEAN';
import BOOLEAN from 'hcms-constants/BOOLEAN';
import USER_ROLES from 'hcms-constants/USER_ROLES';

const toDisplayLabel = (val) => val.displayLabel || val.label;

class Constant {
  constructor(CONSTANT) {
    this.CONSTANT = sortArrayBy(
      CONSTANT.map((op) => ({
        value: op.value,
        label: op.label,
        displayLabel: op.displayLabel,
        name: op.name || constantCase(op.label),
        meta: op,
      })),
      (val) => toDisplayLabel(val).toLowerCase(),
    );
  }

  get VALUES() {
    return this.CONSTANT.map((val) => val.value);
  }

  get LABELS() {
    return this.CONSTANT.map(toDisplayLabel);
  }

  get OPTIONS() {
    return this.CONSTANT.map((val) => ({
      ...val,
      label: toDisplayLabel(val),
    }));
  }

  get DICT_OBJ() {
    return Object.fromEntries(
      this.CONSTANT.map((val) => [val.name, val.value]),
    );
  }

  get DICT() {
    return new Proxy(this.DICT_OBJ, {
      get(target, prop) {
        if (Object.prototype.hasOwnProperty.call(target, prop)) {
          return target[prop];
        }
        return target[prop];
      },
    });
  }

  getByLabel(label) {
    if (!label) {
      return null;
    }
    return this.CONSTANT.find(
      (i) => i.label === label || i.displayLabel === label,
    );
  }

  getByValue(value) {
    return this.CONSTANT.find((i) => i.value === value);
  }

  toValue(label) {
    const v = this.getByLabel(label);
    return v ? v.value : undefined;
  }

  toLabel(value) {
    const v = this.getByValue(value);
    return v ? v.displayLabel || v.label : '';
  }

  toMeta(value) {
    const v = this.getByValue(value);
    return v ? v.meta : undefined;
  }
}

export const antiBooleanUtils = new Constant(ANTI_BOOLEAN);
export const booleanUtils = new Constant(BOOLEAN);
export const roleUtils = new Constant(USER_ROLES);
export { Constant };
