<template>
  <custom-date-picker
    v-model="model"
    class="custom-date-picker"
    size="small"
    v-bind="$attrs"
    v-on="otherListeners"
    :picker-options="customPickerOptions">
  </custom-date-picker>
</template>
<script>
import { DatePicker } from 'element-ui';
import { cloneJSON, setTime } from 'global-utils';
import moment from 'moment';
import {
  localToSystem as localToSystemTz,
  systemToLocal as systemToLocalTz,
} from 'tz-utils';
import Vue from 'vue';

const ElDatePicker = Vue.extend(DatePicker);
const origDatePickerFormatter = ElDatePicker.options.computed.displayValue;
const CustomDatePicker = ElDatePicker.extend({
  props: {
    clearable: {
      type: Boolean,
    },
    format: {
      type: String,
      default: 'dd MMM yyyy',
    },
    formatter: {
      type: Function,
    },
  },
  computed: {
    displayValue() {
      if (!this.formatter) {
        return origDatePickerFormatter.call(this);
      }
      return this.formatter(localToSystemTz(this.value));
    },
  },
});

const checkDate = (val) =>
  !val ||
  moment.isMoment(val) ||
  (Array.isArray(val) && !val.map(checkDate).includes(false));

export default {
  name: 'DatePicker',

  components: {
    CustomDatePicker,
  },

  props: {
    value: {
      required: true,
      validator: checkDate,
    },
    end: {
      type: Boolean,
    },
    defaultTime: {
      type: String,
    },
    future: {
      type: Boolean,
    },
    pickerOptions: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    otherListeners() {
      const listeners = cloneJSON(this.$listeners);
      delete listeners.input;
      delete listeners.change;
      return listeners;
    },
    customPickerOptions() {
      return {
        ...this.pickerOptions,
        firstDayOfWeek: 1,
      };
    },
    model: {
      get() {
        if (!this.value) {
          return this.value;
        }

        if (Array.isArray(this.value)) {
          return this.value.map(systemToLocalTz);
        }
        return systemToLocalTz(this.value);
      },
      set(value) {
        if (!value) {
          this.$emit('input', value);
          return;
        }

        if (Array.isArray(value)) {
          this.$emit('input', value.map(this.toOutput, this));
          return;
        }

        this.$emit('input', this.toOutput(value));
      },
    },
  },
  methods: {
    toOutput(localValue) {
      const NOW = moment();
      const value = localToSystemTz(localValue);
      if (this.end) {
        return value.endOf('day');
      }
      if (this.defaultTime) {
        return setTime(value, this.defaultTime);
      }
      if (this.future && NOW > value) {
        return NOW;
      }
      return value;
    },
  },
};
</script>
<style lang="scss">
.custom-date-picker {
  vertical-align: middle;
}
</style>
