import axios from 'axios';
import { clearUserCookies, currentUser } from 'auth-utils';
import { SERVER_ADDRESS, AUTH as AUTH_PATH } from 'hcms-constants/APP_PATHS';

axios.defaults.baseURL = SERVER_ADDRESS;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Authorization = `Token ${currentUser.token}`;
axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 403) {
    if (error.config.url.indexOf('api/auth') === -1) {
      clearUserCookies();
    }
    window.location.replace(`${AUTH_PATH}?redirect_url=${window.location.href}`);
  }
  throw error;
});
